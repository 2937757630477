"use client";

import { useAuth0 } from "@equipmentshare/auth0-react";
import {
  CLIENT_CONSTANTS,
  FleetConfigurationClientProvider,
  FleetConfigurationClientProviderConfig,
} from "@fleet-configuration/client";

import env from "@/env";

export type AuthWrapperProps = {
  children: React.ReactNode;
};

const fleetConfigurationClientProviderConfig: FleetConfigurationClientProviderConfig =
  {
    environment: env.environment,
    endpoint: CLIENT_CONSTANTS.FLEET_CONFIGURATION_GRAPHQL_PATH,
    notificationsEndpoint:
      CLIENT_CONSTANTS.FLEET_CONFIGURATION_GRAPHQL_NOTIFICATIONS_PATH,
  };

export const FleetConfigurationClientProviderWithAuth = (
  props: AuthWrapperProps,
) => {
  const { children } = props;
  const { getAccessTokenSilently } = useAuth0();

  return (
    <FleetConfigurationClientProvider
      config={fleetConfigurationClientProviderConfig}
      getAccessTokenSilently={getAccessTokenSilently}
    >
      {children}
    </FleetConfigurationClientProvider>
  );
};
